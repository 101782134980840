<template>
  <div>
    <v-banner class="lavBanner" single-line height="400" color="#f5f8fd">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/bannerWisdomEducation400.jpg"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex max-width-1200">
          <v-card color="transparent" flat>
            <div class="d-flex flex-no-wrap">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  教育行业解决方案
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  助力教育行业发展，将虚拟现实与教育行业相结合<br>提升教育教学质量的同时带动孩子们的主观能动性增强记忆
                </v-card-title>
                <!-- <v-card-actions class="mt-16">
                  <v-btn
                      width="160"
                      class="font-size-20 rounded-pill"
                      color="#FFFF"
                      style="color:#2470FF"
                  >
                    立即咨询
                  </v-btn>
                </v-card-actions> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container style="padding-left: 5%;padding-right: 5%;">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:80px">
            <p>IMPROVE TEACHING QUALITY</p>
            <div>
              <v-card-title class="font-size-40 py-0 justify-center">AR/VR提高教育教学质量</v-card-title>
            </div>
        </div>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="9">
              <v-row>
                <v-col cols="4"  v-for="(card,index) in data.sheetList" :key="index">
                    <v-card elevation="0">
                      <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="120" height="120">
                        <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template></v-img></div>
                      <v-card-title class="font-size-24 font-weight-bold justify-center text-align-center" v-text="card.title"></v-card-title>
                      <v-card-text class="font-size-14 text-align-center"  style="color:#666" v-text="card.content"></v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container class="pb-14"> 
        <div class="public-title" style="margin-bottom:47px">
            <p>VIRTUAL REALITY CLASSROOM</p>
            <div>
              <v-card-title class="font-size-40 py-3 justify-center">AR/VR与教学教育相结合下的虚拟现实课堂</v-card-title>
            </div>
        </div>
        <v-row v-for="(item,index) in data.itemList" :key="index" class="align-center px-14">
          <v-col cols="2"></v-col>
          <v-col cols="4" :class="index == 0?'':'mt-5'" style="padding:0">
            <v-card elevation="0">
              <div class='d-flex justify-center'>
                <v-img v-if="index % 2 == 0" :src="item.src" height="300" width="600" class="flex-grow-0 rounded-xl">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template>
                </v-img>
              </div>
              <v-card-title v-if="index % 2 != 0" class="font-size-24 mr-10 font-weight-bold" v-text="item.title"></v-card-title>
              <v-card-text v-if="index % 2 != 0" class="font-size-14 mr-10"  v-text="item.content" style="color:#666"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" :class="index == 0?'':'mt-5'" style="padding:0">
            <v-card elevation="0">
              <div class='d-flex justify-end'>
                <v-img v-if="index % 2 != 0" :src="item.src" height="300" width="600" class="flex-grow-0 rounded-xl">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <v-card-title v-if="index % 2 == 0" class="font-size-24 font-weight-bold ml-10" v-text="item.title"></v-card-title>
              <v-card-text style="color:#666" v-if="index % 2 == 0" class="font-size-14 ml-10"  v-text="item.content"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
let _this
import lavData from '@/static/lavData.js'
export default {
  name: "IntelligentCulturalTourism",
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight/2,
    data:lavData.WisdomEducation
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
}
};
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
</style>